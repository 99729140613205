import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import history from '../../../history';
import { bindQuote } from '../../../actions/quotes';
import SpinnerScreen from '../../shared/SpinnerScreen';
import Axios from 'axios';
class OneincPayment extends React.Component {

  componentDidMount() {
    this.fetchDocument();
  }

  async fetchDocument(){
    const { quote } = this.props;
    const systemName = 'PTS'
  
    const responseData = await Axios.get('tokens/token', {
      params: { system: systemName }
    });

    let token = responseData.value

    if (!quote.selected_pay_plan){
      history.push(`/bol/quotes/${quote.id}/rates`)
    } else{
      this.handleSubmit(quote, token)
    }
  }

  initEventHandlers(container) {
    const { quote } = this.props;
    let final_data;

    console.log(container)
    // remove all old handlers for case when dialog is reopened to avoid double handling
    container.off();

    container.on('portalOne.load',() => {
      console.log('portalOne.load event raised');
    });

    container.on('portalOne.paymentComplete', (e, data) => {
      final_data = data
      console.log('TransactionId : ' + data.transactionId);
      console.log('Payment Token : ' + data.tokenId);
      console.log('Session ID: ' + data.sessionId);
    });

    container.on('portalOne.error', (e, data) => {
      alert(data.Description);
    });

    container.on('portalOne.unload', () => {
      if (final_data){
        const payment_plan_code = quote.selected_pay_plan.plan_code

        history.push(`/bol/signatures`)
        this.props.dispatch(bindQuote({ ...quote, payment_plan_code }, final_data))
      }else{
        history.push(`/bol/quotes/${quote.id}/rates`)
      }
    });
  }


  handleSubmit(quote, token) {
    // event.preventDefault();
    const { selected_pay_plan, drivers, address } = quote;
    const addressLine = `${address.line1} ${address.city} ${address.state}`
    this.getCustomerId(drivers, token).then(custId => {

      fetch(`${process.env.REACT_APP_PORTAL_URL}/API/api/Session/Create?portalOneAuthenticationKey=${token}&CustomerId=` + custId)
        .then(response => response.json())
        .then(data => {
          let container = window.$('#portalOneContainer');
          container.portalOne();
          this.initEventHandlers(container);
          let dialog = container.data('portalOne');
          //This information would come from carrier's system
          dialog.makePayment({
            'paymentCategory': 'CreditCard',
            'feeContext': 'PaymentWithoutFee',
            'convenienceFeeType': 'Renewal',
            'amountContext': 'SelectAmount',
            'minAmountDue': selected_pay_plan.deposit / 100,
            'billingZip': quote.zip_code,
            'billingAddressStreet': addressLine,
            'policyHolderName': `${drivers[0].first_name} ${drivers[0].last_name}`,
            'clientReferenceData1': quote.quote_number,
            'confirmationDisplay': 'true',
            'saveOption': 'Save',
            'accountGroupCode': 'Default',
            'acknowledgmentRequired': 'true',
            'customerId': custId,
            'sessionId': data.PortalOneSessionKey
          });
        });
    })
  }
  
   getCustomerId(drivers, portalOneAuthKey) {
    return new Promise((resolve, reject) => {
      //Check to see if customerID exists in carrier system. If not, create one.
      //Note the POST body information could either be pulled from the UI or from
      //carrier backend depending on the UI design.
      var customerIDExists = false;  //Replace this with a call to customer database
      var customerId = "";
      if (customerIDExists) {
        customerId = "";
        resolve(customerId);
      } else {
        const options = {
          method: 'POST',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify({
            PortalOneAuthenticationKey: `${portalOneAuthKey}`,
            ExternalCustomerId: drivers[0].id,
            CustomerName: `${drivers[0].first_name} ${drivers[0].last_name}`
          })
        };

        fetch(`${process.env.REACT_APP_PORTAL_URL}/Api/Api/Customer/CreateAccount`, options)
          .then(response => response.json())
          .then(response => resolve(response.CustomerId))
          .catch(err => console.error(err));
      }
    })
  }

  render() { 
    return (
      <>
      <Helmet>
        <script async defer
        src={`${process.env.REACT_APP_PORTAL_URL}/GenericModalV2/PortalOne.js`}
        type="text/javascript"></script>
      </Helmet>
      <div>
        <SpinnerScreen title = "" />
        <div id="portalOneContainer"></div>
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  quote: state.data.quote
});

export default connect(mapStateToProps)(OneincPayment);
